// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js'
// import 'core-js/modules/es6.array.find-index.js'

(function() {
	var win = $(window),
		ww = win.innerWidth(),
		wh = win.innerHeight(),
		window_width = win.width(),
		ws = win.scrollTop();
	var resizeWindow = function(){
		ww = win.innerWidth();
		wh = win.innerHeight();
	}
	var previousWidth = window.innerWidth;

	$(window)
		.on('load', function () {
			AllSliderCont();
			PitdContMouse();
			IndexScroll();
			if(window_width > 1024){
				IndexScrollCube();
				MouseMoveItem();
			}
			
			if (isIPadProAndPortrait()) {
				$('.cu-boxcontpad').addClass('is-padpro');
				window.addEventListener('resize', function() {
					location.reload();
				});
			} else{
				$('.cu-boxcontpad').addClass('no-padpro');
				window.addEventListener('resize', function() {
					location.reload();
				});
			}

			setTimeout(() => {
				$('.in-kv').addClass('open');
			}, 600);
		})
		.on('scroll', function () {
			setTimeout(() => {
				IndexScroll();
			}, 0);
			if(window_width > 1024){
				IndexScrollCube();
			}
		})
		.on('resize', function () {
			window.onresize = function(event) {
				var currentWidth = window.innerWidth;
			
				// 判断屏幕宽度是否发生变化
				if (currentWidth !== previousWidth) {
					location.reload();
				}
			
				// 更新 previousWidth
				previousWidth = currentWidth;
			};
			AllSliderCont();
			IndexScroll();
		});

	// -----------------------------------
	// IPADPRO判斷
	// -----------------------------------
	function isIPadProAndPortrait() {
		// 检测是否为 iPad Pro
		var isIPadPro = isIPadProByScreenSize() || isIPadProByPlatform();
	
		// 检测是否为竖屏模式（直立模式）
		var isPortrait = window.matchMedia('(orientation: portrait)').matches || window.orientation === 0 || window.orientation === 180;
	
		return isIPadPro && isPortrait;
	}
	function isIPadProByScreenSize() {
		var screenWidth = window.screen.width;
		var screenHeight = window.screen.height;
	
		// iPad Pro 12.9 英寸 (3rd 和 4th Gen) 的分辨率为 2732x2048
		// iPad Pro 11 英寸 (1st 和 2nd Gen) 的分辨率为 2388x1668
		// 这里仅列出了一些常见的 iPad Pro 分辨率，你可以根据需要添加更多分辨率。
		if (
			(screenWidth === 2732 && screenHeight === 2048) ||
			(screenWidth === 2388 && screenHeight === 1668) ||
			(screenWidth === 1024 && screenHeight === 1366)
		) {
			return true;
		}
	
		return false;
	}
	function isIPadProByPlatform() {
		var platform = navigator.platform.toLowerCase();
	
		// iPad Pro 可能会在平台中包含 "iPad" 和 "Mac" 的信息
		return platform.includes('ipad') && platform.includes('mac');
	}
	// -----------------------------------
	// 頁面滾動時的動畫判斷
	// -----------------------------------
	function IndexScroll(){
		var kvH = $('.in-kv').height();
		// 頁面滾動時的動畫判斷
		var $animation_elements = $('.js-animate');
		var window_height = win.height();
		var window_top_position = win.scrollTop();
		var window_bottom_position = (window_top_position + window_height);

		$.each($animation_elements, function() {
			var $element = $(this);
			var element_height = $element.outerHeight();
			var element_top_position = $element.offset().top - (window_height / 1.2);
			var element_bottom_position = (element_top_position + element_height + (element_height / 3));

			//check to see if this current container is within viewport
			if ((element_bottom_position >= window_top_position) && (element_top_position <= window_top_position)) {
				$element.addClass('hover');
			}else if(window_height > kvH * 1.8){
				$('.in-kv').addClass('hover');
			}else{
				$element.removeClass('hover');
			}
		});


		var scroll_top = $(document).scrollTop();
		var scroll_top_height = scroll_top + $(window).height();
		var inkv_top = $('.in-kv').offset().top;
		var s5 = (scroll_top_height - inkv_top) / 8;

		$('.pagetext.kv').css('transform', 'rotate(6deg) translateX(-' + s5 + 'px)');
		$('.pagetext.news').css('transform', 'translateX(' + s5 + 'px)');
	}

	function IndexScrollCube(){
		if($('.in-cu').hasClass('hover') == true){
			$('.god-introduce').addClass('is-topcube');
			$('.cu-boxcont').removeClass('pagescroll');
		}else{
			$('.god-introduce').removeClass('is-topcube');
			$('.cu-boxcont').addClass('pagescroll');
		}

		if($('.in-pro').hasClass('hover') == true){
			$('.cubebg-box').addClass('is-cubecont');
		}else{
			$('.cubebg-box').removeClass('is-cubecont');
		}
	}

	function PitdContMouse(){
		$(document).on('click', '.pitd-box', function() {
			if($(this).hasClass('subtitle') == false){
				var hover = $(this).data('hover');
				$('.perbg').removeClass('is-active');
				$('.pitd-box').addClass('is-active');
				$('.perbg[data-hover=' + hover + ']').addClass('is-active');
				// setTimeout(() => {
				// }, 600);
			}
		});
		
		// $(document).on('mouseover', '.pitd-box', function() {
		// 	// setTimeout(() => {
		// 	// }, 600);
		// 	$('.perbg').removeClass('is-active');
		// 	$('.pitd-box').removeClass('is-active');
		// });
	}

	function AllSliderCont(){
		var NewsSlider = new Swiper('.swiper-container.newscard', {
			// autoplay:{delay:8,autoplayDisableOninteraction:false},
			loop: !1,
			autoplayDisableOninteraction:false,
			spaceBetween: 0,
			slidesPerView: 1,
			autoHeight: !0,
			watchSlidesVisibility: true,//防止不可点击
			watchSlidesProgress: true,
			navigation:{nextEl:".news-ctr_left .swiper-arrow.next",prevEl:".news-ctr_left .swiper-arrow.prev"},
			loopAdditionalSlides:1,
			pagination:{el:".newscard .swiper-pagination",clickable:!0},
			slideToClickedSlide: !0,
		});

		var ComicSlider = new Swiper('.swiper-container.comiccard', {
			// autoplay:{delay:8,autoplayDisableOninteraction:false},
			loop: !0,
			autoplayDisableOninteraction:false,
			centeredSlides: true,
			spaceBetween: 30,
			slidesPerView: 4.8,
			autoHeight: !1,
			watchSlidesVisibility: true,//防止不可点击
			watchSlidesProgress: true,
			loopAdditionalSlides:1,
			slideToClickedSlide: !0,
			navigation:{nextEl:".comiccard .swiper-arrow.next",prevEl:".comiccard .swiper-arrow.prev"},
			breakpoints: {
				0: {
					slidesPerView: 1.6,
					spaceBetween: 10,
				},
				641: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1024: {
					spaceBetween: 10,
				},
				1201: {
					spaceBetween: 30,
				}
			},
		});

		var VideoSlider = new Swiper('.swiper-container.videocard', {
			loop: true,
			// autoplay: {
			// 	delay: 8000,
			// 	disableOnInteraction: true,
			// },
			centeredSlides: true,
			spaceBetween: 0,
			slidesPerView: 2,
			loopAdditionalSlides: 2,
			autoHeight: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			slideToClickedSlide: true,
			navigation:{nextEl:".in-video .videocard-arrow.next",prevEl:".in-video .videocard-arrow.prev"},
			pagination: {
				el: ".videocard .swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				0: {
					slidesPerView: 1.3,
				},
				374: {
					slidesPerView: 1.6,
				},
				641: {
					slidesPerView: 2,
				}
			},
			on: {
				init: function () {
					console.log('swiper initialized');
				},
				slideChange: function () {
					console.log('slide changed to: ', this.activeIndex);
				},
				transitionEnd: function () {
					console.log('transition ended');
				}
			},
		});
	}

	function MouseMoveItem(){
		var Per1 = document.getElementById('KvPer1');
		var Per2 = document.getElementById('KvPer2');
		var Per3 = document.getElementById('KvPer3');
		var Per4 = document.getElementById('KvPer4');
		var Per5 = document.getElementById('KvPer5');
		var Per1Move = new Parallax(Per1,{invertX:!0,invertY:!0,frictionX:.06,frictionY:.06});
		var Per2Move = new Parallax(Per2,{invertX:!0,invertY:!0,frictionX:.06,frictionY:.02});
		var Per3Move = new Parallax(Per3,{invertX:!0,invertY:!0,frictionX:.06,frictionY:.06});
		var Per4Move = new Parallax(Per4,{invertX:!0,invertY:!0,frictionX:.02,frictionY:.1});
		var Per5Move = new Parallax(Per5,{invertX:!0,invertY:!0,frictionX:.01,frictionY:.03});
	}
	

})();



